import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    Divider,
    Card,
    CardActionArea,
    CardContent,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@material-ui/core';
import { ANNUAL_REPORT } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { SemipolarLoading } from 'react-loadingg';
import Analytics from '../../analytics';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CSSTransition } from 'react-transition-group';

const rooturl = process.env.REACT_APP_ROOT_DOCUMENT_FOLDER;
const root = process.env.REACT_APP_ROOT_APIS;
class AnnualReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            years: [],

            inItems: false,
            isLoading: true,
            value: null,
            inChoose: false
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            Analytics.pageView(window.location.pathname);
            this.fetchData();
        }, 300);
    };

    openURL = (url) => {
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300);
    };

    fetchData = () => {
        fetch(`${root}annual_report.php`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        let finalJson = this.getYears(res);
                        this.setState({
                            isLoading: false,
                            years: finalJson,
                            data: res,
                            inItems: true,
                            inChoose: true
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getYears = (arr) => {
        let year = [];
        let finalJson = [];
        arr.map((val, ind) => {
            if (Number(val.year)) year.push(val.year);
            return 0;
        });
        year = year.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        year.map((val, ind) => {
            let x = val;
            x = { value: val, year: x };
            finalJson.push(x);
            return 0;
        });

        return finalJson;
    };

    renderItems = () => {
        return this.state.data.map((val, ind) => {
            //let date = new Date('' + val.date).toDateString();
            let url = rooturl + val.document;

            if (
                (this.state.value != null && val.year === this.state.value) ||
                this.state.value == null
            )
                return (
                    <CSSTransition
                        timeout={700}
                        in={this.state.inItems}
                        classNames={'documentTrans'}>
                        <div className="docItems">
                            <Card
                                onClick={() => {
                                    this.openURL(url);
                                }}
                                style={{ width: '100%', height: '100%' }}>
                                <CardActionArea
                                    style={{ width: '100%', height: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '55%',
                                            backgroundColor: '#d32f2f',
                                            color: 'white'
                                        }}>
                                        <AssessmentIcon
                                            style={{ fontSize: '100px' }}
                                        />
                                    </div>
                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            width: '88%',
                                            flexDirection: 'column',
                                            textAlign: 'start',
                                            height: '35%'
                                        }}>
                                        <Typography
                                            className="documentText"
                                            variant="body1"
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600',
                                                alignItems: 'center'
                                            }}>
                                            {val.name}
                                        </Typography>
                                        {/* <Typography
                      className="documentText"
                      variant="caption"
                      color="textSecondary"
                      style={{
                        textAlign: 'start',
                        fontWeight: '600',
                      }}>
                      {`Upload Date : ${date}`}
                    </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </CSSTransition>
                );
            else return null;
        });
    };
    handleChange = (event) => {
        this.setState(
            { value: event.target.value, inItems: false, isLoading: true },
            () => {
                setTimeout(() => {
                    this.setState({ inItems: true, isLoading: false });
                }, 500);
            }
        );
    };

    renderMenu = () => {
        return this.state.years.map((val, ind) => {
            return (
                <MenuItem class="menuitem" value={val.value}>
                    {val.year}
                </MenuItem>
            );
        });
    };

    render() {
        let noc = Math.floor(this.props.windowWidth / 200);
        noc = noc - 1;
        let x = '';
        for (let i = 0; i < noc; i++) {
            x = x + 'auto';
            if (i + 1 < noc) x = x + ' ';
        }

        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={ANNUAL_REPORT} />
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1050 ? '170px' : '130px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        <div
                            style={{
                                width:
                                    this.props.windowWidth > 725
                                        ? '50%'
                                        : '100%',
                                display: 'flex',
                                textAlign: 'start',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}>
                                <KeyboardBackspaceIcon
                                    color="primary"
                                    style={{
                                        marginRight: '15px',
                                        fontSize: '50px'
                                    }}
                                />
                            </IconButton>
                            <Typography
                                color="primary"
                                variant="h4"
                                style={{
                                    fontWeight: '600',
                                    marginLeft: '8px'
                                }}>
                                Annual Report
                            </Typography>
                        </div>
                        {this.props.windowWidth > 725 ? (
                            <CSSTransition
                                unmountOnExit
                                timeout={700}
                                in={this.state.inChoose}
                                classNames={'postInAnimation'}>
                                <FormControl
                                    style={{
                                        alignSelf: 'right',
                                        width: '300px'
                                    }}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Annual Year
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        label="Age">
                                        <MenuItem class="menuitem" value={null}>
                                            {'All'}
                                        </MenuItem>
                                        ;{this.renderMenu()}
                                    </Select>
                                </FormControl>
                            </CSSTransition>
                        ) : null}
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '5px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.props.windowWidth < 725 ? (
                    <div style={{ width: '100%' }}>
                        <CSSTransition
                            unmountOnExit
                            timeout={700}
                            in={this.state.inChoose}
                            classNames={'postInAnimation'}>
                            <FormControl
                                variant="standard"
                                style={{ alignSelf: 'right', width: '300px' }}>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Annual Year
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    label="Annual Year">
                                    <MenuItem class="menuitem" value={null}>
                                        All
                                    </MenuItem>
                                    {this.renderMenu()}
                                </Select>
                            </FormControl>
                        </CSSTransition>
                    </div>
                ) : null}
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',

                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'grid',
                            gridTemplateColumns: x,
                            justifyContent:
                                noc <= 1 ? 'space-around' : 'space-between',
                            padding: '8px',
                            rowGap: '30px'
                        }}>
                        {this.renderItems()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No data avaliable
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(AnnualReport);
