import React from 'react';
import { withRouter } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { LANDING_PAGE } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig';
import {
    IconButton,
    Typography,
    Divider,
    Link,
    Button
} from '@material-ui/core';
import { SemipolarLoading } from 'react-loadingg';
import { CSSTransition } from 'react-transition-group';
import QuickLinks from '../../components/QuickLinks/Index';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FlagImgBig from '../../assets/FlagImgBig.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import Analytics from '../../analytics';
import Social from '../../components/Social/Index';
import './styles.css';
const rooturl = process.env.REACT_APP_ROOT_DOCUMENT_FOLDER;
const root = process.env.REACT_APP_ROOT_APIS;
const rootimages = process.env.REACT_APP_ROOT_CROUSEL_FOLDER;
class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSocialVisible: true,
            tagLine:
                '“We are all for ourselves, each one of us is for all of us.”',
            //welcome text paragraphs as arrays
            welcomeText: [
                'The Government Colleges in West Bengal were the pioneers of Higher Education in India and the teachers in State Education Service boast of a glorious history of two hundred years. In 1967, an Association comprising of all categories of teachers and librarians of these colleges was formed, to ensure better service conditions and to sustain a proper educational environment in Government Colleges in West Bengal.',
                'The objectives of the Association as enshrined in its constitution are: “To strive for and ensure the development of a democratic bias in the control and administration of the Government Colleges in West Bengal and to secure, to that end, such delegation of powers to the Staff Councils of Government Colleges in West Bengal as should enable them to advise the Principals and higher authorities in matters related to the interest of the institutions or to the individual or collective interests of the teachers posted there ;”'
            ],
            crouselData: [
                { url: 'http://www.wbgcta.org/Images/PhotoGallery/2015/3.JPG' },
                { url: 'http://www.wbgcta.org/Images/PhotoGallery/2015/4.JPG' },
                { url: 'http://www.wbgcta.org/Images/PhotoGallery/2015/8.JPG' },
                { url: 'http://www.wbgcta.org/Images/PhotoGallery/2015/9.JPG' }
            ],
            latestPostData: [],
            videos: [],
            interactionToPost: false,
            translatePost: 0,
            transitionPostType: 0,
            crouselIn: false,
            isPostLoading: true,
            isCrouselDataLoading: true,
            itemsToLoad: 7
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            Analytics.pageView(window.location.pathname);
            this.fetchPosts();
            this.fetchImages();
        }, 300);
    };

    fetchImages = () => {
        fetch(`${root}crousel.php`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        let x = [];
                        res.map((val, ind) => {
                            let y = { url: rootimages + val.img };
                            x.push(y);
                            return 0;
                        });

                        this.setState(
                            { isCrouselDataLoading: false, crouselData: x },
                            () => {
                                if (!this.state.isPostLoading)
                                    this.setState({
                                        translatePost: -1,
                                        crouselIn: true
                                    });
                            }
                        );
                    })
                    .catch((err) => {
                        Analytics.error({ description: err, fatal: true });
                        console.log(err);
                    });
            })
            .catch((err) => {
                Analytics.error({ description: err, fatal: false });
                console.log(err);
            });
    };

    fetchPosts = () => {
        fetch(`${root}latest_post.php?items=${this.state.itemsToLoad}`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        let x = [];
                        res.map((val, ind) => {
                            x.push(val);
                            return 0;
                        });
                        res.map((val, ind) => {
                            x.push(val);
                            return 0;
                        });

                        this.setState(
                            {
                                isPostLoading: false,
                                latestPostData: x,
                                translatePost: -1
                            },
                            () => {
                                this.translatePostFunc();
                                if (!this.state.isCrouselDataLoading)
                                    this.setState({
                                        crouselIn: true
                                    });
                            }
                        );
                    })
                    .catch((err) => {
                        Analytics.error({ description: err, fatal: true });
                        console.log(err);
                    });
            })
            .catch((err) => {
                Analytics.error({ description: err, fatal: true });
                console.log(err);
            });
    };

    translatePostFunc = () => {
        let { translatePost, latestPostData } = this.state;
        let zt = 155.4 * (latestPostData.length / 2);
        zt = -zt;
        let delay = 2000;
        if (translatePost < zt || translatePost === 0) delay = 1000;

        setTimeout(() => {
            let { interactionToPost } = this.state;
            if (interactionToPost) {
                return;
            }
            let z = 155.4 * (latestPostData.length / 2);
            z = -z;
            if (translatePost < z) {
                translatePost = 0;
                this.setState({ transitionPostType: 1, translatePost });
                return;
            }
            translatePost = translatePost - 159.4;

            this.setState({ translatePost });
        }, delay);
    };

    renderPosts = (height, width) => {
        return (
            <div
                style={{
                    display: 'flex',
                    height: height + 'vh',
                    width: width + '%',
                    marginTop: '20px',
                    maxHeight: '420px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                }}>
                <div
                    style={{
                        display: 'flex',
                        height: '60px',
                        width: '90%',
                        maxHeight: '63px',
                        borderWidth: '1px',
                        overflowX: 'hidden',
                        alignItems: 'center',

                        whiteSpace: 'nowrap'
                    }}>
                    <Typography
                        variant="h5"
                        style={{
                            color: '#7b3f00',
                            marginRight: '10px',
                            fontWeight: '600'
                        }}>
                        {'Latest Posts'}
                    </Typography>
                    <Divider
                        style={{
                            height: '3px',
                            width: '100%',
                            backgroundColor: '#7b3f00'
                        }}
                    />
                </div>
                <div
                    ref={(element) => {
                        this.latestPostRef = element;
                    }}
                    style={{
                        display: 'flex',
                        flex: '6',
                        width: '90%',
                        borderWidth: '1px',
                        overflowX: 'hidden',
                        padding: '8px',
                        borderStyle:
                            width === 100
                                ? 'node none node none'
                                : 'solid none solid none',
                        borderColor: '#7b3f00',
                        alignItems: 'flex-start',
                        overflow: 'hidden'
                    }}>
                    <div
                        onMouseEnter={() => {
                            this.setState({ interactionToPost: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ interactionToPost: false }, () => {
                                this.translatePostFunc();
                            });
                        }}
                        onTransitionEnd={() => {
                            if (this.state.transitionPostType === 1) {
                                this.setState({ transitionPostType: 0 }, () => {
                                    this.translatePostFunc();
                                });
                                return;
                            }
                            this.translatePostFunc();
                        }}
                        style={
                            this.state.transitionPostType
                                ? {
                                      transform: `translateY(${this.state.translatePost}px)`,
                                      transition: 'transform 1ms Linear ',
                                      width: '100%',
                                      zIndex: '1'
                                  }
                                : {
                                      transform: `translateY(${this.state.translatePost}px)`,
                                      transition: 'transform 500ms Linear ',
                                      width: '100%',
                                      zIndex: '1'
                                  }
                        }>
                        {this.state.latestPostData.map((val, ind) => {
                            let url = rooturl + val.document;
                            return (
                                <div
                                    key={val.name + ind}
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '155.4px',
                                        justifyContent: 'space-evenly',
                                        flexDirection: 'column',
                                        paddingTop: '2px',
                                        textAlign: 'start',
                                        paddingBottom: '2px'
                                    }}>
                                    <Typography className="textdivPost">
                                        {val.name}
                                    </Typography>
                                    {/* <Link color="primary" href={val.category == "Govt Orders" ?"governmentorders":val.category.toLowerCase().replace(/ /g, "")}> */}
                                    <Typography
                                        className="textdivPost"
                                        color="textSecondary"
                                        variant="caption">
                                        {val.category === 'Govt Orders'
                                            ? `In category: Government Orders`
                                            : `In category: ${val.category}`}
                                    </Typography>
                                    {/* </Link> */}
                                    {/* <Typography color="textSecondary" variant="caption">
                    {'Uploaded On: ' + date}
                  </Typography> */}

                                    <Link
                                        href={url}
                                        color="primary"
                                        style={{ width: 'fit-content' }}>
                                        {'View post'}
                                    </Link>
                                    {this.state.latestPostData.length - 1 !==
                                    ind ? (
                                        <Divider
                                            style={{
                                                width: '100%',
                                                height: '1px',
                                                backgroundColor: 'grey',
                                                marginTop: '5px'
                                            }}></Divider>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    social = () => {
        return (
            <Button
                onClick={() => {
                    Analytics.event({
                        category: 'Redirects',
                        action: 'Facebook',
                        label: 'Redirected to facebook page'
                    });
                    window.open('https://www.facebook.com/WBGCTA/');
                }}
                style={{
                    margin: '8px 0px 8px 0px',
                    color: 'white',
                    backgroundColor: '#3b5998',
                    width: 'fit-content'
                }}>
                <FacebookIcon style={{ color: 'white', fontSize: '30px' }} />
                <Typography style={{ fontWeight: '600', marginLeft: '10px' }}>
                    WBGCTA
                </Typography>
            </Button>
        );
    };

    render() {
        if (this.state.isPostLoading || this.state.isCrouselDataLoading) {
            return (
                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        flex: '1',
                        height: '91vh'
                    }}>
                    <HelmetConfig data={LANDING_PAGE} />
                    <div
                        style={{
                            width: '100%',
                            height:
                                this.props.windowWidth > 1050
                                    ? '185px'
                                    : '180px'
                        }}></div>
                    <div
                        style={{
                            width: '100%',
                            height: '50vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={LANDING_PAGE} />
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1050 ? '185px' : '180px'
                    }}></div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width:
                                this.props.windowWidth > 1048 ? '65%' : '100%'
                        }}>
                        <CSSTransition
                            timeout={1000}
                            in={this.state.crouselIn}
                            classNames={'crouselInAnimation'}>
                            <div
                                style={{
                                    zIndex: '0',
                                    width:
                                        this.props.windowWidth > 1048
                                            ? '85%'
                                            : '95%',
                                    height: 'auto',
                                    overflow: 'hidden'
                                }}>
                                <Carousel
                                    autoPlay={3000}
                                    offset={50}
                                    draggable={true}
                                    keepDirectionWhenDragging={true}
                                    animationSpeed={1000}
                                    infinite
                                    stopAutoPlayOnHover
                                    arrowLeft={
                                        <IconButton
                                            color="primary"
                                            style={{ marginRight: '5px' }}>
                                            <NavigateBeforeIcon></NavigateBeforeIcon>
                                        </IconButton>
                                    }
                                    arrowLeftDisabled={null}
                                    arrowRight={
                                        <IconButton
                                            color="primary"
                                            style={{ marginLeft: '5px' }}>
                                            <NavigateNextIcon></NavigateNextIcon>
                                        </IconButton>
                                    }
                                    arrowRightDisabled={
                                        <IconButton disabled>
                                            <NavigateNextIcon></NavigateNextIcon>
                                        </IconButton>
                                    }
                                    addArrowClickHandler
                                    dots={true}
                                    centered>
                                    {this.state.crouselData.map((val, int) => {
                                        return (
                                            <img
                                                alt={val.url}
                                                key={int}
                                                className="crimage"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto'
                                                }}
                                                src={val.url}
                                            />
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </CSSTransition>
                    </div>
                    {this.props.windowWidth > 1048 ? (
                        <CSSTransition
                            timeout={1000}
                            in={this.state.crouselIn}
                            classNames={'postInAnimation'}>
                            {this.renderPosts(55, 30)}
                        </CSSTransition>
                    ) : null}
                </div>

                <div
                    style={{
                        display: 'flex',
                        height: '60px',
                        width: '90%',
                        maxHeight: '63px',
                        borderWidth: '1px',
                        overflowX: 'hidden',
                        marginTop: '50px',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        marginBottom: '30px'
                    }}>
                    <Typography
                        variant="h4"
                        style={{
                            marginRight: '10px',
                            fontWeight: '600',
                            color: '#7b3f00'
                        }}>
                        {'Welcome'}
                    </Typography>
                    <Divider
                        style={{
                            height: '3px',
                            width: '100%',
                            backgroundColor: '#7b3f00'
                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.props.windowWidth >= 750 ? (
                        <img
                            alt=""
                            src={FlagImgBig}
                            style={{
                                position: 'relative',
                                top: '0px',
                                height: 'auto',
                                width: 'auto',
                                zIndex: 1
                            }}></img>
                    ) : null}
                    <div
                        style={{
                            width: '90%',
                            padding: '15px',
                            display: 'flex',

                            flexDirection: 'column'
                        }}
                        className={
                            this.props.windowWidth >= 750 ? null : 'welcome'
                        }>
                        {this.state.welcomeText.map((val, ind) => {
                            return (
                                <Typography
                                    key={ind}
                                    variant={
                                        this.props.windowWidth < 800
                                            ? 'body2'
                                            : 'body1'
                                    }
                                    paragraph={true}
                                    style={{ textAlign: 'justify' }}>
                                    {val}
                                </Typography>
                            );
                        })}
                        <Typography
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {this.state.tagLine}
                        </Typography>
                        {this.social()}
                    </div>
                </div>

                <Social isVisible={this.state.isSocialVisible} />
                {/* {this.state.isSocialVisible ? this.social() : null} */}

                {this.props.windowWidth > 1048 ? null : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                        {this.renderPosts(55, 100)}
                        <Divider
                            style={{
                                width: '90%',
                                height: '2px',
                                backgroundColor: '#7b3f00'
                            }}></Divider>
                    </div>
                )}

                <QuickLinks />
            </div>
        );
    }
}

export default withRouter(LandingPage);
