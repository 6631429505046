import ReactGA from 'react-ga';

let initialize = (id, option = {}) => {
    ReactGA.initialize(id, option);
    return;
};

let pageView = (location) => {
    ReactGA.pageview(location);
    return;
};

let event = (options) => {
    ReactGA.event(options);
};

let error = (options) => {
    ReactGA.exception(options);
};
let analytics = { initialize, pageView, event, error };
export default analytics;
