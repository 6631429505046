import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { Button, Typography, Popover } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import './styles.css';

class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollpos: this.props.windowWidth < 1450 ? 30 : 0,
            startscroll: this.props.windowWidth < 1450 ? 30 : 0,
            count: 0,
            rightarrow: true,
            leftArrow: false,
            endscroll: 100,
            anchorOrganisation: false,
            anchorPublication: false,
            windowWidth: this.props.windowWidth,
            links: [
                { name: 'Home', type: 0, path: '/' },
                { name: 'About', type: 0, path: '/about' },
                { name: 'Constitution', type: 0, path: '/constitution' },
                {
                    name: 'Organization',
                    type: 1,
                    sub: [
                        { name: 'Executive Committee', path: '' },
                        { name: 'Election Commission', path: '' }
                    ]
                },
                {
                    name: 'Government Orders',
                    type: 0,
                    path: '/governmentorders'
                },
                { name: 'Annual Report', type: 0, path: '/annualreport' },
                { name: 'Publication', type: 1, path: '/annualreport' },
                { name: 'Archive', type: 0, path: '/archive' },
                { name: 'Notice', type: 0, path: '/notice' },
                { name: 'Gallery', type: 0, path: '/gallery' },
                {
                    name: 'Government Colleges',
                    type: 0,
                    path: '/governmentcolleges'
                },

                { name: 'Contacts', type: 0, path: '/contacts' }
            ]
        };
        this.referece = null;
    }

    updateOnWindowChange = () => {
        this.setState({
            windowWidth: this.props.windowWidth,
            scrollpos: this.props.windowWidth < 1450 ? 30 : 0,
            startscroll: this.props.windowWidth < 1450 ? 30 : 0,

            count: 0
        });
    };
    routeTo = (route) => {
        this.setState(
            { anchorOrganisation: false, anchorPublication: false },
            () => {
                this.props.history.push(route);
            }
        );
    };

    scrollRight = () => {
        let { scrollpos, count, rightarrow, leftarrow } = this.state;
        if (
            ReactDOM.findDOMNode(this.state.referece).clientWidth +
                scrollpos -
                this.props.windowWidth >
            300
        )
            scrollpos = scrollpos - 300;
        else
            scrollpos =
                scrollpos -
                (ReactDOM.findDOMNode(this.state.referece).clientWidth +
                    scrollpos -
                    this.props.windowWidth +
                    50);
        if (
            ReactDOM.findDOMNode(this.state.referece).clientWidth +
                scrollpos -
                this.props.windowWidth +
                50 !==
            0
        )
            rightarrow = true;
        else rightarrow = false;

        if (scrollpos !== this.state.startscroll) leftarrow = true;
        else leftarrow = false;

        this.setState({ scrollpos, count, rightarrow, leftarrow });
    };

    scrollLeft = (element) => {
        let { scrollpos, count, rightarrow, leftarrow } = this.state;

        if (this.state.startscroll - scrollpos > 300)
            scrollpos = scrollpos + 300;
        else scrollpos = scrollpos + (this.state.startscroll - scrollpos);
        if (
            ReactDOM.findDOMNode(this.state.referece).clientWidth +
                scrollpos -
                this.props.windowWidth +
                50 !==
            0
        )
            rightarrow = true;
        else rightarrow = false;

        if (scrollpos !== this.state.startscroll) leftarrow = true;
        else leftarrow = false;
        this.setState({ scrollpos, count, rightarrow, leftarrow });
    };

    handleClose = () => {
        this.setState({ anchorOrganisation: false, anchorPublication: false });
    };

    render() {
        //if (this.props.windowWidth !== this.state.windowWidth)
        //this.updateOnWindowChange();
        return (
            <div
                id="container"
                className="linksCont"
                ref={(element) => {
                    this.referece = element;
                }}>
                {/* {windowWidth < 1450 && this.state.leftarrow ? (
          <div className="leftArrow" onClick={this.scrollLeft}>
            <NavigateBeforeIcon
              style={{
                zIndex: '10',
              }}></NavigateBeforeIcon>
          </div>
        ) : null}
        {windowWidth < 1450 && this.state.rightarrow ? (
          <div className="rightArrow" onClick={this.scrollRight}>
            <NavigateNextIcon></NavigateNextIcon>
          </div>
        ) : null} */}

                <div
                    style={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns:
                            'auto auto auto auto auto auto auto auto auto auto auto auto',
                        justifyContent: 'space-between',
                        overflowX: 'auto'
                    }}>
                    {this.state.links.map((val, ind) => {
                        return val.type === 1 ? (
                            <Button
                                key={ind}
                                onClick={(event) => {
                                    console.log('Hello');
                                    if (val.name === 'Organization')
                                        this.setState({
                                            anchorOrganisation:
                                                event.currentTarget
                                        });
                                    else if (val.name === 'Publication')
                                        this.setState({
                                            anchorPublication:
                                                event.currentTarget
                                        });
                                }}
                                style={{
                                    paddingLeft: '3px',
                                    paddingRight: '3px',

                                    fontFamily: 'Oswald, sans-serif',
                                    whiteSpace: 'nowrap'
                                }}
                                color={'secondary'}>
                                <Typography
                                    style={{
                                        color: '#7b3f00',
                                        fontSize: '13.5px',

                                        fontFamily: 'Oswald, sans-serif'
                                    }}>
                                    {val.name}
                                </Typography>
                                <ArrowDropDownIcon
                                    style={{}}></ArrowDropDownIcon>
                            </Button>
                        ) : (
                            <Button
                                key={ind}
                                onClick={() => {
                                    this.routeTo(val.path);
                                }}
                                className="butnd"
                                color={'secondary'}>
                                <Typography
                                    style={{
                                        color: '#7b3f00',

                                        fontSize: '13.5px',

                                        fontFamily: 'Oswald, sans-serif'
                                    }}>
                                    {val.name}
                                </Typography>
                            </Button>
                        );
                    })}
                    <Popover
                        id="simple-menu"
                        anchorEl={this.state.anchorPublication}
                        open={Boolean(this.state.anchorPublication)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        style={{ paddingTop: '3px', paddingBottom: '3px' }}
                        onClose={this.handleClose}>
                        <MenuItem
                            class="menuitem"
                            onClick={() => {
                                this.routeTo('/books');
                            }}>
                            Books
                        </MenuItem>
                        <MenuItem
                            class="menuitem"
                            onClick={() => {
                                this.routeTo('/newsletter');
                            }}>
                            News Letter
                        </MenuItem>
                    </Popover>
                    <Popover
                        id="simple-menu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        anchorEl={this.state.anchorOrganisation}
                        style={{ paddingTop: '3px', paddingBottom: '3px' }}
                        open={Boolean(this.state.anchorOrganisation)}
                        onClose={this.handleClose}>
                        <MenuItem
                            class="menuitem"
                            onClick={() => {
                                this.routeTo('/executivecommittee');
                            }}>
                            Executive Committee
                        </MenuItem>
                        <MenuItem
                            class="menuitem"
                            onClick={() => {
                                this.routeTo('/electioncommission');
                            }}>
                            Election Commission
                        </MenuItem>
                    </Popover>
                </div>
            </div>
        );
    }
}

export default withRouter(Links);
