import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Typography, Divider, Avatar } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import Abhijit from '../../assets/abhjit.jpg';
import Utkarsh from '../../assets/utkarsh.jpg';
import DKK from '../../assets/dkk.jpg';
import Analytics from '../../analytics';
import SchoolIcon from '@material-ui/icons/School';
import { DEVS } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
class NoticePage extends React.Component {
    componentDidMount() {
        Analytics.pageView(window.location.pathname);
    }
    render() {
        let width = (((window.innerWidth * 45) / 100) * 30) / 100;
        width = width + 3;

        if ((window.innerWidth * 45) / 100 < 280) width = 120;
        width = width + 'px';
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={DEVS} />
                <div
                    style={{
                        width: '100%',

                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            Developer's Contacts
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '8px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>

                <div
                    style={{
                        margin: '10px',
                        width: '100%',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}>
                    <Typography
                        variant="h5"
                        color="primary"
                        style={{ fontWeight: '600' }}>
                        Supervisor
                    </Typography>
                </div>
                <Divider
                    style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '65%',
                        minWidth: '220px',
                        height: '2px',
                        backgroundColor: '#A9A9A9'
                    }}></Divider>
                <div
                    style={{
                        marginTop: '8px',
                        marginBottom: '25px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <div
                        style={{
                            height: 'fit-content',
                            padding: '8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            margin: '10px',
                            width: '50%',
                            maxWidth: '46%',
                            minWidth: '280px'
                        }}>
                        <Avatar
                            style={{ width: width, height: width }}
                            alt="Remy Sharp"
                            src={DKK}
                        />
                        <Typography
                            variant="h6"
                            style={{ margin: '10px', fontWeight: '600' }}>
                            Dr. Dipak Kumar Kole
                        </Typography>
                        <Typography variant="body2">
                            Associate Professor
                        </Typography>
                        <Typography variant="body2">
                            Computer Science & Engineering
                        </Typography>
                        <Typography variant="body2">
                            Jalpaiguri Government Engineering College
                        </Typography>
                        <Divider
                            style={{
                                marginTop: '30px',
                                marginBottom: '8px',
                                backgroundColor: '#7b3f00',
                                width: '70%',
                                height: '3px',
                                borderRadius: '4px'
                            }}></Divider>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.facebook.com/dipak.kole.7'
                                    );
                                }}
                                color="primary">
                                <FacebookIcon></FacebookIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.linkedin.com/in/dipak-kumar-kole-a2a4ab38/'
                                    );
                                }}
                                color="primary">
                                <LinkedInIcon></LinkedInIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://scholar.google.co.in/citations?user=6nH28EAAAAAJ&hl=en'
                                    );
                                }}
                                color="primary">
                                <SchoolIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        margin: '10px',
                        width: '100%',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}>
                    <Typography
                        variant="h5"
                        color="primary"
                        style={{ fontWeight: '600' }}>
                        Developers
                    </Typography>
                </div>
                <Divider
                    style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '65%',
                        minWidth: '220px',
                        height: '2px',
                        backgroundColor: '#A9A9A9'
                    }}></Divider>
                <div
                    style={{
                        marginBottom: '20px',
                        minHeight: '60vh',
                        display: 'flex',
                        flex: '1',
                        width: '100%',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}>
                    <div
                        style={{
                            height: 'fit-content',
                            padding: '8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            margin: '10px',
                            width: '50%',
                            maxWidth: '46%',
                            minWidth: '280px'
                        }}>
                        <Avatar
                            style={{ width: width, height: width }}
                            alt="Remy Sharp"
                            src={Utkarsh}
                        />
                        <Typography
                            variant="h6"
                            style={{ margin: '10px', fontWeight: '600' }}>
                            Utkarsh Pandey
                        </Typography>
                        <Typography variant="body2">
                            Computer Science & Engineering
                        </Typography>
                        <Typography variant="body2">
                            Jalpaiguri Government Engineering College
                        </Typography>
                        <Divider
                            style={{
                                marginTop: '30px',
                                marginBottom: '8px',
                                backgroundColor: '#7b3f00',
                                width: '70%',
                                height: '3px',
                                borderRadius: '4px'
                            }}></Divider>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.facebook.com/profile.php?id=100006881236840'
                                    );
                                }}
                                color="primary">
                                <FacebookIcon></FacebookIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.linkedin.com/in/utkarsh-pandey-95015b170'
                                    );
                                }}
                                color="primary">
                                <LinkedInIcon></LinkedInIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://twitter.com/utkrsh0611'
                                    );
                                }}
                                color="primary">
                                <TwitterIcon></TwitterIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/iamutkarsh0611/'
                                    );
                                }}
                                color="primary">
                                <InstagramIcon></InstagramIcon>
                            </IconButton>
                        </div>
                    </div>
                    <div
                        style={{
                            height: 'fit-content',
                            padding: '8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            display: 'flex',
                            margin: '10px',
                            width: '50%',
                            maxWidth: '46%',
                            minWidth: '280px'
                        }}>
                        <Avatar
                            style={{ width: width, height: width }}
                            alt="Remy Sharp"
                            src={Abhijit}
                        />
                        <Typography
                            variant="h6"
                            style={{ margin: '10px', fontWeight: '600' }}>
                            Abhijit Burman
                        </Typography>
                        <Typography variant="body2">
                            Computer Science & Engineering
                        </Typography>
                        <Typography variant="body2">
                            Jalpaiguri Government Engineering College
                        </Typography>
                        <Divider
                            style={{
                                marginTop: '30px',
                                marginBottom: '8px',
                                backgroundColor: '#7b3f00',
                                width: '70%',
                                height: '3px',
                                borderRadius: '4px'
                            }}></Divider>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.facebook.com/abhijit.burman.75/'
                                    );
                                }}
                                color="primary">
                                <FacebookIcon></FacebookIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.linkedin.com/in/abhijit-burman-100133157/'
                                    );
                                }}
                                color="primary">
                                <LinkedInIcon></LinkedInIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://twitter.com/abhijitburman12'
                                    );
                                }}
                                color="primary">
                                <TwitterIcon></TwitterIcon>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://www.instagram.com/abhijit.burman/'
                                    );
                                }}
                                color="primary">
                                <InstagramIcon></InstagramIcon>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NoticePage);
