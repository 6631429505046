import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Typography, Divider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { CSSTransition } from 'react-transition-group';
import { SemipolarLoading } from 'react-loadingg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import './styles.css';
import { GOVERNMENT_COLLEGES } from '../../analytics/analytics';
import Analytics from '../../analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
const root = process.env.REACT_APP_ROOT_APIS;
class NoticePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    name: 'name',
                    label: 'Name',
                    options: {
                        filter: false
                    }
                },
                {
                    name: 'category',
                    label: 'Category',
                    options: {
                        filterType: 'multiselect'
                    }
                },
                {
                    name: 'zone',
                    label: 'Zone',
                    options: {
                        filterType: 'multiselect'
                    }
                },
                {
                    name: 'Website',
                    label: 'Webiste',
                    options: {
                        filter: false
                    }
                }
            ],
            data: [],
            options: {
                rowHover: true,
                rowsPerPage: 10,
                downloadOptions: { filename: 'GovernmentColleges-WBGCTA.csv' },
                pagination: true,
                selectableRows: false,
                responsive: 'scrollMaxHeight',
                onRowClick: (rowData, rowMeta) => {
                    if (rowData[3].replace(/ /g, '') === 'N/A') return;
                    Analytics.event({
                        category: 'Redirects',
                        action: 'Colleges',
                        label:
                            rowData[0] + ' url: ' + rowData[3].replace(/ /g, '')
                    });

                    window.open('//' + rowData[3].replace(/ /g, ''), '_blank');
                }
            },
            inItems: false,
            isLoading: true
        };
    }

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        let data = [];
        this.state.data.map((val, ind) => {
            let x = [];
            x.push(val[0].toUpperCase());
            x.push(val[1].toUpperCase());
            x.push(val[2].toUpperCase());
            x.push(val[3]);
            data.push(x);
            return 1;
        });
        setTimeout(() => {
            this.fetchData();
        }, 1000);
    };

    fetchData = () => {
        fetch(`${root}colleges.php`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        //console.log(res);
                        let { data } = this.state;
                        res.map((val, ind) => {
                            let temp = [];
                            temp.push(val.collegeName.toUpperCase());
                            temp.push(val.collegeCategory.toUpperCase());
                            temp.push(val.zone.toUpperCase());
                            temp.push(val.link);
                            data.push(temp);
                            return 0;
                        });
                        this.setState({ isLoading: false }, () => {
                            this.setState({ inItems: true, data });
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isLoading: false });
                    });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    render() {
        let { data, columns, options } = this.state;
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',

                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={GOVERNMENT_COLLEGES} />
                <div
                    style={{
                        width: '100%',

                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            Government Colleges
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '8px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',
                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : (
                    <CSSTransition
                        timeout={700}
                        in={this.state.inItems}
                        classNames={'documentTrans'}
                        style={{ cursor: 'pointer' }}>
                        <div
                            style={{
                                width:
                                    this.props.windowWidth > 900
                                        ? '85%'
                                        : '95%',
                                marginTop: '50px',
                                marginBottom: '50px'
                            }}>
                            <MUIDataTable
                                title={'Government College List   '}
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </div>
                    </CSSTransition>
                )}
                <div
                    style={{
                        width: '100%',
                        height: '30px'
                    }}></div>
            </div>
        );
    }
}

export default withRouter(NoticePage);
