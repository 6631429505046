import React from 'react';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { SemipolarLoading } from 'react-loadingg';
import Analytics from '../../analytics';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './styles.css';
import CloseIcon from '@material-ui/icons/Close';
import { CSSTransition } from 'react-transition-group';

const root = process.env.REACT_APP_ROOT_APIS;
const rootimages = process.env.REACT_APP_ROOT_PHOTOS_FOLDER;
class ImagesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            isError: false,
            BackDropOpen: false,
            BackdropImagePos: 0,
            image: false,
            colNo: Math.floor(this.props.windowWidth / 350) + 1
        };
        this.start = null;
    }
    handleBackDropClose = () => {
        this.setState({ BackDropOpen: false, image: false });
    };

    decresePos = () => {
        let { BackdropImagePos } = this.state;
        BackdropImagePos = BackdropImagePos - 1;

        this.setState({ image: false }, () => {
            this.setState({ BackdropImagePos: BackdropImagePos, image: true });
        });
    };

    increasePos = () => {
        let { BackdropImagePos } = this.state;
        BackdropImagePos = BackdropImagePos + 1;
        this.setState({ image: false }, () => {
            this.setState({ BackdropImagePos: BackdropImagePos, image: true });
        });
    };

    handleBackDropToggel = (pos) => {
        let { BackDropOpen } = this.state;
        this.setState({
            BackDropOpen: !BackDropOpen,
            BackdropImagePos: pos,
            image: true
        });
    };

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        if (!this.props.history.location.state)
            this.props.history.push('/gallery');
        this.fetchData();
    };

    componentWillMount = () => {
        if (!this.props.history.location.state)
            this.props.history.push('/gallery');
    };

    fetchData = () => {
        if (!this.props.history.location.state) return;
        fetch(
            `${root}gallery_images.php?category=${this.props.history.location.state.categoryId}`
        )
            .then((res) => {
                res.json()
                    .then((response) => {
                        //console.log(response);
                        var z = 1;
                        var flag = 0;
                        for (var i = 0; i < response.length; i++) {
                            if (i % 3 === 0 && i !== 0) {
                                flag = 1;
                            }
                            if (flag === 1 && z % this.state.colNo !== 0) {
                                z = z + 2;
                                flag = 0;
                                response[i] = {
                                    ...response[i],

                                    col: 2,
                                    row: 2
                                };
                            } else {
                                z = z + 1;
                                response[i] = {
                                    ...response[i],

                                    col: 1
                                };
                            }
                        }
                        this.setState({ isLoading: false, data: response });
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false, isError: true });
                    });
            })
            .catch((err) => {
                this.setState({ isLoading: false, isError: true });
            });
    };

    popullateData = () => {
        return (
            <div
                style={{
                    width: '97%',

                    display: 'flex',

                    flexWrap: 'wrap',
                    marginRight: '20px',
                    marginLeft: '20px',
                    justifyContent: 'space-around',
                    marginBottom: '15px'
                }}>
                <GridList
                    cellHeight={200}
                    style={{ width: '100%' }}
                    cols={this.state.colNo}>
                    {this.state.data.map((tile, ind) => (
                        <GridListTile
                            className="pic"
                            key={tile.img}
                            cols={tile.col}
                            onClick={() => {
                                this.handleBackDropToggel(ind);
                            }}>
                            <img src={rootimages + tile.img} alt={tile.img} />
                            <GridListTileBar
                                titlePosition={'bottom'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    background:
                                        'linear-gradient(to top, rgba(0,0,0,0.7) 10%, ' +
                                        'rgba(0,0,0,0.2) 80%, rgba(0,0,0,0) 100%)'
                                }}
                                actionPosition="right"
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    };

    onTouchStart = (event) => {
        //console.log('hello');
        this.start = null;
        if (event.touches.length === 1) {
            //just one finger touched
            this.start = event.touches.item(0).clientX;
        } else {
            //a second finger hit the screen, abort the touch
            this.start = null;
        }
    };

    onTouchEnd = (event) => {
        //console.log('wr');
        var offset = 100; //at least 100px are a swipe
        if (this.start) {
            //the only finger that hit the screen left it
            var end = event.changedTouches.item(0).clientX;
            if (end > this.start + offset && this.state.BackdropImagePos > 0) {
                this.decresePos();
            }
            if (
                end < this.start - offset &&
                this.state.BackdropImagePos < this.state.data.length - 1
            ) {
                this.increasePos();
            }
        }
    };

    startListners = () => {
        window.addEventListener('touchstart', this.onTouchStart);
        window.addEventListener('touchend', this.onTouchEnd);
    };

    endListners = () => {
        window.removeEventListener('touchstart', this.onTouchStart);
        window.removeEventListener('touchend', this.onTouchEnd);
    };

    render() {
        if (!this.props.history.location.state) return <div />;
        if (this.state.BackDropOpen) {
            this.startListners();
        } else {
            this.endListners();
        }
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            {this.props.history.location.state.categoryId}
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '5px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>

                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',

                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '98%',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            marginBottom: '40px',
                            justifyContent:
                                this.props.windowWidth < 494
                                    ? 'center'
                                    : 'flex-start'
                        }}>
                        {this.popullateData()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No images avaliable at the moment
                        </Typography>
                    </div>
                )}

                <Backdrop
                    className="backdrop"
                    transitionDuration={300}
                    style={{ zIndex: '1201' }}
                    open={this.state.BackDropOpen}>
                    {this.state.data.length ? (
                        <CSSTransition
                            in={this.state.image}
                            timeout={200}
                            classNames="image">
                            <img
                                alt="full"
                                src={
                                    rootimages +
                                    this.state.data[this.state.BackdropImagePos]
                                        .img
                                }
                                style={{
                                    maxWidth: '90%',
                                    maxHeight: '80%',
                                    marginTop: '15px'
                                }}
                            />
                        </CSSTransition>
                    ) : null}
                    {window.innerWidth > 770 ? (
                        this.state.BackdropImagePos !== 0 ? (
                            <IconButton
                                aria-label="back"
                                variant={'outlined'}
                                color="inherit"
                                onClick={this.decresePos}
                                style={{ position: 'absolute', left: '15px' }}>
                                <ArrowBackIosIcon
                                    style={{
                                        color: 'white',
                                        fontSize: '40px'
                                    }}></ArrowBackIosIcon>
                            </IconButton>
                        ) : null
                    ) : null}
                    {window.innerWidth > 770 ? (
                        this.state.BackdropImagePos !==
                        this.state.data.length - 1 ? (
                            <IconButton
                                aria-label="back"
                                variant={'outlined'}
                                color="inherit"
                                onClick={this.increasePos}
                                style={{ position: 'absolute', right: '15px' }}>
                                <ArrowForwardIosIcon
                                    style={{
                                        color: 'white',
                                        fontSize: '40px'
                                    }}></ArrowForwardIosIcon>
                            </IconButton>
                        ) : null
                    ) : null}
                    <IconButton
                        aria-label="back"
                        variant={'outlined'}
                        color="inherit"
                        onClick={this.handleBackDropClose}
                        style={{
                            position: 'absolute',
                            right: '50px',
                            top: '50px'
                        }}>
                        <CloseIcon
                            style={{
                                color: 'white',
                                fontSize: '40px'
                            }}
                        />
                    </IconButton>
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(ImagesPage);
