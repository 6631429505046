import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { Button } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Analytics from './analytics';
import Header from './components/Header/Header';
import Links from './components/Links/Links';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import LandingPage from './pages/LandingPage/LandingPage';
import ContactPage from './pages/ContactPage/ContactPage';
import NoticePage from './pages/NoticePage/NoticePage';
import GoveOrdersPage from './pages/GoveOrdersPage/GoveOrdersPage';
import ArchivePage from './pages/ArchivePage/ArchivePage';
import ConstitutionPage from './pages/ConstitutionPage/ConstitutionPage';
import GovernmentCollege from './pages/GovernmentCollege/GovernmentCollege';
import AboutPage from './pages/AboutPage/AboutPage';
import Books from './pages/Books/Books';
import NewsLetter from './pages/NewsLetter/NewsLetter';
import AnnualReport from './pages/AnnualReport/AnnualReport';
import Gallery from './pages/Gallery/Gallery';
import WBUNIV from './pages/WBuniv/Index.js';
import ExecutiveCommittee from './pages/Organization/Executive Committee/Index';
import ElectionCommission from './pages/Organization/Election Commission/Index';
import Dev from './pages/DevContacts/Index';
import './App.css';
import ImagesPage from './pages/Images/ImagesPage';
let ANALYTICS_ID = process.env.REACT_APP_ANALYTICS_ID;
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            scrollPos: window.scrollY
        };
    }

    componentDidMount = () => {
        Analytics.initialize(ANALYTICS_ID);

        window.addEventListener('resize', () => {
            const widthNow = window.innerWidth;
            this.setState({ windowWidth: widthNow });
        });
        document.addEventListener('scroll', () => {
            const scrollNow = window.scrollY;
            this.setState({ scrollPos: scrollNow });
        });
    };
    render() {
        let scrollBut = false;
        if (this.state.scrollPos > 250) scrollBut = true;
        return (
            <div className="App">
                <Router>
                    <CSSTransition
                        timeout={1000}
                        in={scrollBut}
                        unmountOnExit
                        classNames="scrollbutton">
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            style={{
                                backgroundColor: '#B0702E',
                                position: 'fixed',
                                bottom: '20px',
                                zIndex: '3',

                                right: '33px'
                            }}>
                            <ArrowUpwardIcon
                                style={{
                                    color: 'white',
                                    fontSize: '28px',
                                    fontWeight: '600'
                                }}
                            />
                        </Button>
                    </CSSTransition>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            width: '100%',
                            zIndex: '101'
                        }}>
                        <Header
                            scrollY={this.state.scrollPos}
                            windowWidth={this.state.windowWidth}
                        />
                        {this.state.windowWidth <= 1150 ? null : (
                            <Links
                                scrollY={this.state.scrollPos}
                                windowWidth={this.state.windowWidth}
                            />
                        )}
                    </div>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(props) => (
                                <LandingPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/about"
                            render={(props) => (
                                <AboutPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/wbuniv"
                            render={(props) => (
                                <WBUNIV
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/constitution"
                            render={(props) => (
                                <ConstitutionPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/executivecommittee"
                            render={(props) => (
                                <ExecutiveCommittee
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />

                        <Route
                            path="/electioncommission"
                            render={(props) => (
                                <ElectionCommission
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />

                        <Route
                            path="/annualreport"
                            render={(props) => (
                                <AnnualReport
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/gallery/images"
                            render={(props) => (
                                <ImagesPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/gallery"
                            render={(props) => (
                                <Gallery
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />

                        <Route
                            path="/books"
                            render={(props) => (
                                <Books
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/newsletter"
                            render={(props) => (
                                <NewsLetter
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/governmentorders"
                            render={(props) => (
                                <GoveOrdersPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />

                        <Route
                            path="/governmentcolleges"
                            render={(props) => (
                                <GovernmentCollege
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/notice"
                            render={(props) => (
                                <NoticePage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/archive"
                            render={(props) => (
                                <ArchivePage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/contacts"
                            render={(props) => (
                                <ContactPage
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/dev"
                            render={(props) => (
                                <Dev
                                    scrollY={this.state.scrollPos}
                                    windowWidth={this.state.windowWidth}
                                />
                            )}
                        />
                        <Route
                            path="/404"
                            render={(props) => <NotFound />}></Route>
                        <Redirect to="/404"></Redirect>
                    </Switch>
                </Router>
                <Footer />
            </div>
        );
    }
}

export default App;
