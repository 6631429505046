import React from 'react'
import {Helmet} from 'react-helmet'



export default function(props) {
    
    return(
        <Helmet>
            {props.data.title  ? <title>{props.data.title}</title> : <title>WBGCTA</title>}
            <meta name="description" content={ props.data.description }/>
        </Helmet>
    )
}