import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { NOT_FOUND } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = { windowWidtsh: window.innerWidth };
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '92vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgb(253, 245, 232)'
                }}>
                <HelmetConfig data={NOT_FOUND} />
                <Typography
                    variant="h1"
                    color="textPrimary"
                    style={{
                        alignSelf: 'center',
                        textAlign: 'center',
                        marginTop: '120px',
                        marginLeft: '10px',
                        marginRight: '10px'
                    }}>
                    Error 404!
                </Typography>
                <Typography
                    variant="h4"
                    style={{ marginTop: '17px' }}
                    color="textSecondary">
                    The page you are looking for doesn't exist
                </Typography>
                <Button
                    onClick={() => {
                        this.props.history.replace('/');
                    }}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '30px' }}>
                    Home
                </Button>
            </div>
        );
    }
}

export default withRouter(NotFound);
