import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    Divider,
    Card,
    CardActionArea,
    CardContent,
    MenuItem,
    TextField,
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SemipolarLoading } from 'react-loadingg';
import Analytics from '../../analytics';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { GOVERNMENT_ORDERS } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
import { CSSTransition } from 'react-transition-group';
const rooturl = process.env.REACT_APP_ROOT_DOCUMENT_FOLDER;
const root = process.env.REACT_APP_ROOT_APIS;
class GoveOrdersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            inItems: false,
            isLoading: true,
            gono: null,
            title: null,
            year: -1,
            anchorEl: null,
            anchorE2: null,
            years: [],
            searchBy: -1
        };
    }

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        setTimeout(() => {
            this.fetchData();
        }, 500);
    };

    getYears = (arr) => {
        let year = [];
        let finalJson = [];
        arr.map((val, ind) => {
            if (Number(val.year)) year.push(val.year);
            return 1;
        });
        year = year.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        year.map((val, ind) => {
            let x = Number(val);
            finalJson.push(x);
            return 0;
        });

        return finalJson;
    };

    openURL = (url) => {
        if (url === rooturl) return;
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300);
    };
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickMenu = (event) => {
        this.setState({ anchorE2: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorE2: null });
    };

    fetchData = () => {
        fetch(`${root}/gov_order.php`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        //console.log(res);
                        let finalJson = this.getYears(res);
                        //console.log(finalJson);
                        this.setState({ isLoading: false, data: res }, () => {
                            this.setState({ inItems: true, years: finalJson });
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    renderItems = () => {
        let { title, year, gono } = this.state;
        return this.state.data.map((val, ind) => {
            //let date = new Date('' + val.date).toDateString();
            let url = rooturl + val.document;

            if (
                (gono == null ||
                    val.ordernumber
                        .toLowerCase()
                        .includes(gono.toLowerCase())) &&
                (title == null ||
                    val.name.toLowerCase().includes(title.toLowerCase())) &&
                (year === -1 || Number(year) === Number(val.year))
            )
                return (
                    <CSSTransition
                        timeout={700}
                        in={this.state.inItems}
                        classNames={'documentTrans'}>
                        <div className="docItems" style={{ height: '300px' }}>
                            <Card
                                onClick={() => {
                                    this.openURL(url);
                                }}
                                style={{ width: '100%', height: '100%' }}>
                                <CardActionArea
                                    style={{ width: '100%', height: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '40%',
                                            backgroundColor: '#d32f2f',
                                            color: 'white'
                                        }}>
                                        <DescriptionIcon
                                            style={{ fontSize: '100px' }}
                                        />
                                    </div>
                                    <CardContent
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            width: '88%',
                                            flexDirection: 'column',
                                            textAlign: 'start',
                                            height: '50%'
                                        }}>
                                        <Typography
                                            className="departmentText"
                                            variant="caption"
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600',
                                                alignItems: 'center'
                                            }}>
                                            {val.name}
                                        </Typography>
                                        <Typography
                                            className="departmentText"
                                            variant="caption"
                                            color="textSecondary"
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600'
                                            }}>
                                            {`${val.ordernumber}`}
                                        </Typography>
                                        <Typography
                                            className="departmentText"
                                            variant="caption"
                                            color="textSecondary"
                                            style={{
                                                textAlign: 'start',
                                                fontWeight: '600'
                                            }}>
                                            {`Year: ${val.year}`}
                                        </Typography>
                                        {/* <Typography
                      className="departmentText"
                      variant="caption"
                      color="textSecondary"
                      style={{
                        textAlign: 'start',
                        fontWeight: '600',
                      }}>
                      {`Dated: ${date}`}
                    </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </CSSTransition>
                );
            else return null;
        });
    };

    handleSearchby = (event) => {
        this.setState({ searchBy: -1 }, () => {
            setTimeout(() => {
                this.setState({ gono: null, title: null }, () => {
                    this.setState({ searchBy: event.target.value });
                });
            }, 100);
        });
    };

    handleYear = (event) => {
        this.setState({ year: event.target.value });
    };

    handleGoNo = (event) => {
        this.setState({ gono: event.target.value });
    };
    handleTitle = (event) => {
        this.setState({ title: event.target.value });
    };

    renderMenu = () => {
        return this.state.years.map((val, ind) => {
            return <MenuItem value={val}>{val}</MenuItem>;
        });
    };

    render() {
        let noc = Math.floor(this.props.windowWidth / 200);
        noc = noc - 1;
        let x = '';
        for (let i = 0; i < noc; i++) {
            x = x + 'auto';
            if (i + 1 < noc) x = x + ' ';
        }

        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={GOVERNMENT_ORDERS} />
                <div
                    style={{
                        width: '100%',

                        height:
                            this.props.windowWidth > 1050 ? '170px' : '160px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <div
                            style={{
                                width:
                                    this.props.windowWidth < 730
                                        ? '100%'
                                        : '60%',
                                display: 'flex',
                                textAlign: 'start',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}>
                                <KeyboardBackspaceIcon
                                    color="primary"
                                    style={{
                                        marginRight: '15px',
                                        fontSize: '50px'
                                    }}
                                />
                            </IconButton>

                            {this.state.searchBy !== -1 ? (
                                this.state.searchBy ? (
                                    <TextField
                                        style={{ width: '50%' }}
                                        label="Search By Go Number"
                                        defaultValue=""
                                        onChange={this.handleGoNo}
                                        value={this.state.gono}
                                        inputProps={{ autoFocus: true }}
                                    />
                                ) : (
                                    <TextField
                                        defaultValue=""
                                        style={{ width: '50%' }}
                                        label="Search By Name"
                                        onChange={this.handleTitle}
                                        inputProps={{ autoFocus: true }}
                                        value={this.state.title}
                                    />
                                )
                            ) : (
                                <Typography
                                    color="primary"
                                    variant="h4"
                                    style={{
                                        fontWeight: '600',
                                        marginLeft: '8px'
                                    }}>
                                    Government Orders
                                </Typography>
                            )}
                            {this.state.searchBy !== -1 ? (
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            title: null,
                                            gono: null,
                                            searchBy: -1
                                        });
                                    }}>
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </div>
                        {this.props.windowWidth > 730 ? (
                            <div
                                style={{
                                    width: '40%',
                                    display: 'flex',
                                    textAlign: 'start',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        alignSelf: 'right',
                                        width: '150px',
                                        marginRight: '8px'
                                    }}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Search
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={
                                            this.state.searchBy === -1
                                                ? null
                                                : this.state.searchBy
                                        }
                                        onChange={this.handleSearchby}
                                        label="Search">
                                        <MenuItem value={0}>
                                            {'Search By Name'}
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            {'Search By GO Number'}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl
                                    variant="standard"
                                    style={{
                                        alignSelf: 'right',
                                        width: '150px'
                                    }}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Annual Year
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.value}
                                        onChange={this.handleYear}
                                        label="Annual Year">
                                        <MenuItem value={-1}>
                                            {'Any Year'}
                                        </MenuItem>
                                        {this.renderMenu()}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : null}
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '8px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.props.windowWidth <= 730 ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}>
                        <FormControl
                            variant="standard"
                            style={{
                                alignSelf: 'right',
                                width: '150px',
                                marginRight: '8px'
                            }}>
                            <InputLabel id="demo-simple-select-outlined-label">
                                Search
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={
                                    this.state.searchBy === -1
                                        ? null
                                        : this.state.searchBy
                                }
                                onChange={this.handleSearchby}
                                label="Search">
                                <MenuItem value={0}>
                                    {'Search By Name'}
                                </MenuItem>
                                <MenuItem value={1}>
                                    {'Search By GO Number'}
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            style={{ alignSelf: 'right', width: '150px' }}>
                            <InputLabel id="demo-simple-select-outlined-label">
                                Annual Year
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.value}
                                onChange={this.handleYear}
                                label="Annual Year">
                                <MenuItem value={-1}>{'Any Year'}</MenuItem>
                                {this.renderMenu()}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',

                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'grid',
                            gridTemplateColumns: x,
                            justifyContent:
                                noc <= 1 ? 'space-around' : 'space-between',
                            padding: '8px',
                            rowGap: '30px'
                        }}>
                        {this.renderItems()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No data avaliable
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(GoveOrdersPage);
