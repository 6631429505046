import React from 'react';
class GoogleMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      
      <iframe
        title="map"
        src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=22.567648,88.3550009&amp;q=+(GOENKA%20COLLEGE%20OF%20COMMERCE%20AND%20BUSINESS%20ADMINISTRATION)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
        aria-hidden="true"
        frameborder="1"
        tabindex="-1"
        
        style={{
          zIndex: '2',
          position: 'relative',
          width: '90%',
          
          height: '90%',
          borderWidth: '2px',
          
          borderColor:'black'
        }}></iframe>
    );
  }
}

export default GoogleMaps;
