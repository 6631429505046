import React from 'react';
import Analytics from '../../analytics';
import { Typography, Divider, Link } from '@material-ui/core';
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: {
                //address having three address lines
                address: [
                    'GOENKA COLLEGE OF COMMERCE AND BUSINESS ADMINISTRATION',
                    '210, Bepin Behari Ganguly Street',
                    'Kolkata - 700 012'
                ],
                phone: '(033) 2219 2061',
                email: 'wbgcta1967@gmail.com'
            },
            links: [
                [
                    {
                        name: 'All India Council for Technical Education',
                        url: 'http://www.aicte-india.org/'
                    },
                    { name: 'AIFUCTO', url: 'http://www.aifucto.org/' },
                    {
                        name: 'Centeral Board of Secondary Education',
                        url: 'http://www.cbse.nic.in/'
                    },
                    {
                        name: 'Council for scientific and industrial research',
                        url: 'http://csir.res.in/'
                    },
                    {
                        name:
                            'Council for the indian school certificate examination',
                        url: 'https://www.cisce.org/'
                    },
                    {
                        name:
                            'Department of higher education government of West Bengal',
                        url: 'https://wbhed.gov.in/'
                    }
                ],
                [
                    {
                        name: 'Ministry of Human Resource Development',
                        url: 'http://www.mhrd.gov.in/'
                    },
                    {
                        name: 'Ministry of Science and Technology',
                        url: 'https://dst.gov.in/'
                    },
                    {
                        name: 'National Assessment and Accreditation Council',
                        url: 'http://www.naac.gov.in/'
                    },
                    {
                        name: 'National Council for Teacher Education',
                        url: 'https://ncte.gov.in/Website/Index.aspx'
                    },
                    {
                        name: 'National digital library of India',
                        url: 'https://ndl.iitkgp.ac.in/'
                    },
                    {
                        name: 'West Bengal council of Rabindra open schooling',
                        url: 'http://wbcros.in/'
                    }
                ],
                [
                    {
                        name: 'University Grants Commission',
                        url: 'http://www.ugc.ac.in/'
                    },
                    {
                        name: 'West Bengal Board of secondary education',
                        url: 'http://wbbse.org/'
                    },
                    {
                        name:
                            "West Bengal college and university teacher's association",
                        url: 'http://www.wbcuta.org/'
                    },
                    {
                        name:
                            'West Bengal Council of Higher Secondary Education',
                        url: 'http://www.wbchse.nic.in/'
                    },
                    {
                        name: 'West Bengal Madras Board of Education',
                        url: 'http://www.wbbme.org/'
                    },
                    {
                        name: 'West Bengal Institutes and Universities',
                        url: '/wbuniv'
                    }
                ]
            ]
        };
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flex: '1',
                    marginTop: '50px',
                    padding: '20px',
                    color: 'white',
                    flexWrap: 'wrap',
                    backgroundColor: '#7b3f00'
                }}>
                <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap' }}>
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            padding: '15px',
                            minWidth: '250px',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}>
                        <Typography>Quick Links</Typography>
                        <Divider
                            style={{
                                width: '80%',
                                height: '2px',
                                backgroundColor: 'white',
                                marginBottom: '20px'
                            }}
                        />
                        {this.state.links[0].map((val, ind) => {
                            return (
                                <Link
                                    href={val.url}
                                    key={ind}
                                    onClick={() => {
                                        Analytics.event({
                                            category: 'Redirects',
                                            action: `Redirected`,
                                            label: `to ${val.name} url: ${val.url}`
                                        });
                                    }}
                                    style={{
                                        textAlign: 'start',
                                        color: 'white'
                                    }}>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: '600' }}>
                                        {'► ' + val.name}
                                    </Typography>
                                </Link>
                            );
                        })}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            padding: '15px',
                            minWidth: '250px',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}>
                        <Typography>Quick Links</Typography>
                        <Divider
                            style={{
                                width: '80%',
                                height: '2px',
                                backgroundColor: 'white',
                                marginBottom: '20px'
                            }}
                        />
                        {this.state.links[1].map((val, ind) => {
                            return (
                                <Link
                                    href={val.url}
                                    key={ind}
                                    onClick={() => {
                                        Analytics.event({
                                            category: 'Redirects',
                                            action: `Redirected`,
                                            label: `to ${val.name} url: ${val.url}`
                                        });
                                    }}
                                    style={{
                                        textAlign: 'start',
                                        color: 'white'
                                    }}>
                                    <Typography
                                        variant="caption"
                                        style={{
                                            textAlign: 'start',
                                            fontWeight: '600'
                                        }}>
                                        {'► ' + val.name}
                                    </Typography>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap' }}>
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            padding: '15px',
                            minWidth: '250px',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}>
                        <Typography>Quick Links</Typography>
                        <Divider
                            style={{
                                width: '80%',
                                height: '2px',
                                backgroundColor: 'white',
                                marginBottom: '20px'
                            }}
                        />
                        {this.state.links[2].map((val, ind) => {
                            return (
                                <Link
                                    href={val.url}
                                    key={ind}
                                    onClick={() => {
                                        if (val.url === '/wbuniv') return;
                                        Analytics.event({
                                            category: 'Redirects',
                                            action: `Redirected`,
                                            label: `to ${val.name} url: ${val.url}`
                                        });
                                    }}
                                    style={{
                                        textAlign: 'start',
                                        color: 'white'
                                    }}>
                                    <Typography
                                        variant="caption"
                                        key={ind}
                                        style={{
                                            textAlign: 'start',
                                            fontWeight: '600'
                                        }}>
                                        {'► ' + val.name}
                                    </Typography>
                                </Link>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            padding: '15px',
                            paddingRight: '20px',
                            minWidth: '250px',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            textAlign: 'start',
                            flexDirection: 'column'
                        }}>
                        <Typography>Quick Contacts</Typography>
                        <Divider
                            style={{
                                width: '80%',
                                height: '2px',
                                backgroundColor: 'white',
                                marginBottom: '20px'
                            }}
                        />
                        <Typography
                            variant="caption"
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {this.state.contact.address[0]}
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {this.state.contact.address[1]}
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {this.state.contact.address[2]}
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {'Phone : ' + this.state.contact.phone}
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{ textAlign: 'start', fontWeight: '600' }}>
                            {'Email : ' + this.state.contact.email}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
