import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    Divider,
    Card,
    CardMedia,
    CardActionArea,
    CardContent
} from '@material-ui/core';

import { GALLERY } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
import { SemipolarLoading } from 'react-loadingg';
import Analytics from '../../analytics';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CSSTransition } from 'react-transition-group';
import noimage from '../../assets/noimage.png';
import './styles.css';

const root = process.env.REACT_APP_ROOT_APIS;
const rootimages = process.env.REACT_APP_ROOT_PHOTOS_FOLDER;
class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            inItems: false,
            isLoading: true,
            backDrop: false,
            backDropElement: null,
            image: false
        };
    }

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        setTimeout(() => {
            this.fetchData();
        }, 1000);
    };

    fetchData = () => {
        fetch(`${root}gallery_album.php`)
            .then((respose) => {
                respose
                    .json()
                    .then((res) => {
                        this.setState({ isLoading: false, data: res }, () => {
                            this.setState({ inItems: true });
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isLoading: false });
                    });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    openURL = (name) => {
        this.props.history.push('/gallery/images', {
            categoryId: name
        });
    };

    renderAlbums = () => {
        return this.state.data.map((val, ind) => {
            let date = new Date('' + val.date).toDateString();
            return (
                <CSSTransition
                    timeout={700}
                    in={this.state.inItems}
                    classNames={'documentTrans'}>
                    <Card
                        className="albumItems"
                        style={{ width: '250px', zIndex: '2' }}>
                        <CardActionArea
                            onClick={() => {
                                this.openURL(val.name);
                            }}>
                            <CardMedia
                                style={{ height: '140px' }}
                                image={
                                    val.img === 'NULL' || val.img === null
                                        ? noimage
                                        : rootimages + val.img
                                }
                                title={val.name}
                            />
                            <CardContent>
                                <Typography
                                    className="albumName"
                                    gutterBottom
                                    variant="body2"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start',
                                        height: '40px'
                                    }}
                                    component="h2">
                                    {val.name}
                                </Typography>
                                <Typography
                                    className="albumName"
                                    gutterBottom
                                    color="textSecondary"
                                    variant="caption"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start'
                                    }}
                                    component="h2">
                                    {'Created On: ' + date}
                                </Typography>
                                {/* <Typography
                  className="albumDes"
                  variant="body2"
                  color="textSecondary"
                  component="p">
                  {val.description == '' ||
                  val.description == 'NULL' ||
                  val.description == null
                    ? 'No description'
                    : val.description}
                </Typography> */}
                            </CardContent>
                        </CardActionArea>
                        {/* <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  this.setState(
                    { backDrop: true, backDropElement: ind, image: true },
                    () => {
                      this.startListners();
                    }
                  );
                }}>
                Learn More
              </Button>
            </CardActions> */}
                    </Card>
                </CSSTransition>
            );
        });
    };

    renderBackDrop = () => {
        return null;
    };

    render() {
        let noc = Math.floor(this.props.windowWidth / 250);
        noc = noc - 1;
        let x = '';
        for (let i = 0; i < noc; i++) {
            x = x + 'auto';
            if (i + 1 < noc) x = x + ' ';
        }
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={GALLERY} />
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                {this.state.backDrop ? this.renderBackDrop() : null}
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            Gallery
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '5px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',

                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'grid',
                            gridTemplateColumns: x,
                            justifyContent:
                                noc <= 1 ? 'space-around' : 'space-between',
                            padding: '8px',
                            rowGap: '30px'
                        }}>
                        {this.renderAlbums()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No data avaliable
                        </Typography>
                    </div>
                )}
                <div style={{ height: '50px' }}></div>
            </div>
        );
    }
}

export default withRouter(Gallery);
