let LANDING_PAGE = {
    title: 'WBGCTA - West Bengal Government College Teachers’ Association',
    description:
        "The Government Colleges in West Bengal were the pioneers of Higher Education in India and the teachers in State Education Service boast of a glorious history of two hundred years. In 1967, an Association named 'West Bengal Government College Teacher Association' comprising of all categories of teachers and librarians of these colleges was formed, to ensure better service conditions and to sustain a proper educational environment in Government Colleges in West Bengal. The objectives of the Association as enshrined in its constitution are: “To strive for and ensure the development of a democratic bias in the control and administration of the Government Colleges in West Bengal and to secure, to that end, such delegation of powers to the Staff Councils of Government Colleges in West Bengal as should enable them to advise the Principals and higher authorities in matters related to the interest of the institutions or to the individual or collective interests of the teachers posted there."
};

let ABOUT_PAGE = {
    title: 'About Us | WBGCTA',
    description: 'Read about out history'
};

let ANNUAL_REPORT = {
    title: 'Annual Report | WBGCTA',
    description: 'Read our annual reports'
};

let ARCHIVE = {
    title: 'Archive | WBGCTA',
    description: 'View our archives'
};

let BOOKS = {
    title: 'Books | WBGCTA',
    description: 'Read books recommended by us'
};

let CONSTITUTION = {
    title: 'Constitution | WBGCTA',
    description: "Read constitution and it's laws"
};

let CONTACT = {
    title: 'Contacts | WBGCTA',
    description: 'Contact us and view our location'
};

let GALLERY = {
    title: 'Gallery | WBGCTA',
    description: 'View our photos of different events'
};

let GOVERNMENT_ORDERS = {
    title: 'Government Orders | WBGCTA',
    description: 'View latest goverment orders'
};

let NEWS_LETTER = {
    title: 'News Letter | WBGCTA',
    description: 'Read news letters offered by us'
};

let GOVERNMENT_COLLEGES = {
    title: 'Government Colleges | WBGCTA',
    description: 'View government college in West Bengal and their details'
};

let NOTICE = {
    title: 'Notice | WBGCTA',
    description: 'View latest notices by us'
};

let UNIVS = {
    title: 'Universities & Institutes | WBGCTA',
    description:
        'View different universties and institutes details in West Bengal'
};

let EXECUTIVE_COMMITTEE = {
    title: 'Executive Committee | WBGCTA',
    description: 'View our executive committee members and their detials.'
};

let ELECTION_COMMITTEE = {
    title: 'Election Committee | WBGCTA',
    description: 'View our election committee members and their detials.'
};

let DEVS = {
    title: "Developer's Contacts | WBGCTA",
    description: 'View our website developers and their contacts'
};

let NOT_FOUND = {
    title: 'ERROR 404 | NOT FOUND',
    description: 'Page Not Found'
};

export {
    NOT_FOUND,
    LANDING_PAGE,
    ABOUT_PAGE,
    ANNUAL_REPORT,
    ARCHIVE,
    BOOKS,
    CONSTITUTION,
    CONTACT,
    DEVS,
    GALLERY,
    GOVERNMENT_COLLEGES,
    GOVERNMENT_ORDERS,
    NEWS_LETTER,
    NOTICE,
    ELECTION_COMMITTEE,
    EXECUTIVE_COMMITTEE,
    UNIVS
};
