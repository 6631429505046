import React from 'react';
import { Typography, Link } from '@material-ui/core';
import './styles.css';
import CodeIcon from '@material-ui/icons/Code';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      onDev:false
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', () => {
      const widthNow = window.innerWidth;
      this.setState({ windowWidth: widthNow });
    });
    
  };

  
  render() {
    return (
      <div className="footerContainer">

        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            fontSize: '14px',
            fontFamily: 'Roboto Condensed, sans-serif',
            alignSelf: 'center',
            textAlign: 'center',
            marginLeft: '10px',
            marginRight: '10px',
          }}>
          Copyright Ⓒ To West Bengal Government College Teachers’ Association
          2020-2021.
        </Typography>
       <Link href="/dev" style={{ color: '#7b3f00', padding: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CodeIcon style={{ fontSize: "14px", margin: '3px' }}></CodeIcon>
          <Typography variant="caption" >Developer's Contacts</Typography>
        </Link>
      </div>
    );
  }
}

export default Footer;
