import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    SwipeableDrawer,
    Divider
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ContactsIcon from '@material-ui/icons/Contacts';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/Logo.png';
import EnglishName from '../../assets/englishName.png';
import BengaliName from '../../assets/bengaliName.png';
import InfoIcon from '@material-ui/icons/Info';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LanguageIcon from '@material-ui/icons/Language';
import GavelIcon from '@material-ui/icons/Gavel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ImageIcon from '@material-ui/icons/Image';
import SchoolIcon from '@material-ui/icons/School';
import ArchiveIcon from '@material-ui/icons/Archive';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { CSSTransition } from 'react-transition-group';

import './styles.css';
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawer: false,
            namesIn: false
        };
    }

    componentDidMount = () => {
        this.setState({ namesIn: true });
    };

    openDrawer = (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        this.setState({ drawer: true });
    };

    closeDrawer = (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        this.setState({ drawer: false });
    };

    routeTo = (route) => {
        this.setState({ drawer: false }, () => {
            this.props.history.push(route);
        });
    };

    draweritem = () => (
        <TreeView
            style={{
                display: 'flex',
                height: '264px',
                flexDirection: 'column',
                maxWidth: '400px',
                width: '300px',
                minWidth: '70px',
                justifyContent: 'flex-start',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px'
            }}
            defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: '24px' }} />}>
            <Typography
                variant="body1"
                gutterBotto={true}
                color="primary"
                style={{
                    alignSelf: 'center',
                    fontWeight: '600',
                    fontSize: '22px',
                    flexGrow: '1',
                    marginTop: '20px'
                }}>
                {'Menu'}
            </Typography>
            <Divider style={{ marginTop: '20px' }} />
            <TreeItem
                nodeId="1"
                onClick={() => this.routeTo('/')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <HomeIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Home'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="1"
                onClick={() => this.routeTo('/about')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <InfoIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'About Us'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="2"
                onClick={() => this.routeTo('/constitution')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <MenuBookIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Constitution'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="3"
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <LanguageIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Organization'}
                        </Typography>
                    </div>
                }>
                <TreeItem
                    nodeId="31"
                    onClick={() => this.routeTo('/executivecommittee')}
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontWeight: '600', flexGrow: '1' }}>
                                {'Executive Committee'}
                            </Typography>
                        </div>
                    }></TreeItem>

                <TreeItem
                    nodeId="35"
                    onClick={() => this.routeTo('/electioncommission')}
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontWeight: '600', flexGrow: '1' }}>
                                {'Election Commission'}
                            </Typography>
                        </div>
                    }></TreeItem>
            </TreeItem>
            <Divider />
            <TreeItem
                nodeId="4"
                onClick={() => this.routeTo('/governmentorders')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <GavelIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Government Orders'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="6"
                onClick={() => this.routeTo('/annualreport')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <AssessmentIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Annual Report'}
                        </Typography>
                    </div>
                }></TreeItem>
            <Divider />
            <TreeItem
                nodeId="5"
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <FileCopyIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Publication'}
                        </Typography>
                    </div>
                }>
                <TreeItem
                    nodeId="51"
                    onClick={() => this.routeTo('/books')}
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontWeight: '600', flexGrow: '1' }}>
                                {'Books'}
                            </Typography>
                        </div>
                    }></TreeItem>
                <TreeItem
                    nodeId="52"
                    onClick={() => this.routeTo('/newsletter')}
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontWeight: '600', flexGrow: '1' }}>
                                {'News Letter'}
                            </Typography>
                        </div>
                    }></TreeItem>
            </TreeItem>
            <Divider />
            <TreeItem
                nodeId="9"
                onClick={() => this.routeTo('/archive')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <ArchiveIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Archive'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="10"
                onClick={() => this.routeTo('/notice')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <EventNoteIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Notice'}
                        </Typography>
                    </div>
                }
            />
            <Divider />

            <TreeItem
                nodeId="7"
                onClick={() => this.routeTo('/gallery')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <ImageIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Gallery'}
                        </Typography>
                    </div>
                }
            />
            <Divider />
            <TreeItem
                nodeId="8"
                onClick={() => this.routeTo('/governmentcolleges')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <SchoolIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Government Colleges'}
                        </Typography>
                    </div>
                }
            />
            <Divider />

            <TreeItem
                nodeId="11"
                onClick={() => this.routeTo('/contacts')}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                        <ContactsIcon
                            color="textSecondary"
                            style={{ marginRight: '10px', color: '' }}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: '600', flexGrow: '1' }}>
                            {'Contacts'}
                        </Typography>
                    </div>
                }
            />
            <Divider style={{ marginBottom: '100px' }} />
        </TreeView>
    );
    render() {
        const windowWidth = this.props.windowWidth;
        return (
            <header className="header-full">
                <SwipeableDrawer
                    anchor="right"
                    open={this.state.drawer}
                    onClose={this.closeDrawer}
                    onOpen={this.openDrawer}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}>
                    {this.draweritem()}
                </SwipeableDrawer>
                <CSSTransition
                    timeout={500}
                    in={true}
                    classNames={'HeaderInAnimation'}>
                    <div
                        style={{
                            margin: '15px',
                            display: 'flex',
                            width: '73%',
                            alignItems:
                                windowWidth < 636 ? 'flex-start' : 'center',
                            flexDirection: windowWidth < 636 ? 'column' : 'row',
                            justifyContent:
                                windowWidth < 636 ? 'center' : 'flex-start'
                        }}>
                        <div>
                            <img
                                alt="logo"
                                src={Logo}
                                onClick={() => {
                                    this.routeTo('/');
                                }}
                                style={{
                                    display: 'flex',
                                    height: '80px',
                                    width: 'auto',
                                    cursor: 'pointer',
                                    marginBottom:
                                        windowWidth < 636 ? '8px' : '0px'
                                }}></img>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: windowWidth < 636 ? '100%' : '45%'
                            }}>
                            <img
                                alt="bname"
                                src={BengaliName}
                                onClick={() => {
                                    this.routeTo('/');
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: windowWidth < 636 ? '16px' : '26px',
                                    display: 'flex',
                                    marginLeft:
                                        windowWidth < 636 ? '0px' : '15px',
                                    width: '100%',
                                    minWidth:
                                        windowWidth < 636 ? '1px' : '428px'
                                }}></img>
                            <img
                                alt="Ename"
                                src={EnglishName}
                                onClick={() => {
                                    this.routeTo('/');
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: windowWidth < 636 ? '15px' : '25px',
                                    display: 'flex',
                                    marginLeft:
                                        windowWidth < 636 ? '0px' : '15px',
                                    width: '100%',
                                    minWidth:
                                        windowWidth < 636 ? '1px' : '428px'
                                }}></img>
                        </div>
                    </div>
                </CSSTransition>
                <div
                    style={{
                        display: 'flex',
                        width: '24%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {this.props.windowWidth > 1150 ? null : (
                        <IconButton
                            onClick={this.openDrawer}
                            size={'medium'}
                            color={'primary'}>
                            <MenuIcon></MenuIcon>
                        </IconButton>
                    )}
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
