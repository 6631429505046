import React from 'react';
import Analytics from '../../analytics';
import { Typography, Divider } from '@material-ui/core';
const ROOT = process.env.REACT_APP_ROOT_APIS;
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        this.fetchVideos();
    }

    fetchVideos = () => {
        fetch(`${ROOT}/youtube.php`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        let x = res.map((val) => {
                            return {
                                title: val.title,
                                id: this.youtube_parser(val.url)
                            };
                        });
                        this.setState({ data: x });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                Analytics.error({ description: err, fatal: false });
                console.log(err);
            });
    };
    youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    };

    render() {
        if (!this.props.isVisible) return null;
        return (
            <div
                style={{
                    width: '100%',
                    padding: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                <div
                    style={{
                        display: 'flex',
                        height: '60px',
                        width: '90%',
                        maxHeight: '63px',
                        borderWidth: '1px',
                        overflowX: 'hidden',
                        marginTop: '20px',
                        alignItems: 'center',

                        whiteSpace: 'nowrap',
                        marginBottom: '30px'
                    }}>
                    <Typography
                        variant="h4"
                        style={{
                            marginRight: '10px',
                            fontWeight: '600',
                            color: '#7b3f00'
                        }}>
                        {'Social'}
                    </Typography>
                    <Divider
                        style={{
                            height: '3px',
                            width: '100%',
                            backgroundColor: '#7b3f00'
                        }}
                    />
                </div>
                {this.state.data.length ? (
                    <div
                        style={{
                            width: '88%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            padding: '10px',
                            justifyContent: 'space-around'
                        }}>
                        {this.state.data.map((val) => {
                            return (
                                <div
                                    id={val.id}
                                    style={{
                                        display: 'column',
                                        minWidth: '320px',
                                        margin: '5px',
                                        width: '40%',
                                        marginBottom: '10px'
                                    }}>
                                    <iframe
                                        title={val.title}
                                        width="100%"
                                        height="300"
                                        src={`https://www.youtube.com/embed/${val.id}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen="true"></iframe>
                                    <div
                                        style={{
                                            width: '85%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '8px',
                                            textAlign: 'justify'
                                        }}>
                                        <Typography
                                            style={{ width: '100%' }}
                                            variant="body1">
                                            {val.title}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            height: '100px',
                            disaply: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography>Loading...</Typography>
                    </div>
                )}
            </div>
        );
    }
}

export default Index;
