import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    Divider,
    Card,
    CardActionArea,
    CardContent,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { SemipolarLoading } from 'react-loadingg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import Analytics from '../../analytics';
import { NOTICE } from '../../analytics/analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
const rooturl = process.env.REACT_APP_ROOT_DOCUMENT_FOLDER;
const root = process.env.REACT_APP_ROOT_APIS;
class NoticePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            inItems: false,
            isLoading: true,
            value: 'show top 20'
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            Analytics.pageView(window.location.pathname);
            this.fetchData();
        }, 500);
    };

    handleChangeRadio = (event) => {
        this.setState({ value: event.target.value });
    };

    openURL = (url) => {
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300);
    };

    fetchData = () => {
        fetch(`${root}/document.php?category=Notices`)
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        // console.log(res);
                        this.setState({ isLoading: false, data: res }, () => {
                            this.setState({ inItems: true });
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    renderItems = () => {
        return this.state.data.map((val, ind) => {
            // let date = new Date('' + val.date).toDateString();
            let url = rooturl + val.document;
            if (ind > 19 && this.state.value === 'show top 20') return null;
            return (
                <CSSTransition
                    timeout={700}
                    in={this.state.inItems}
                    classNames={'documentTrans'}>
                    <div className="docItems">
                        <Card
                            onClick={() => {
                                this.openURL(url);
                            }}
                            style={{ width: '100%', height: '100%' }}>
                            <CardActionArea
                                style={{ width: '100%', height: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: '55%',
                                        backgroundColor: '#d32f2f',
                                        color: 'white'
                                    }}>
                                    <DescriptionIcon
                                        style={{ fontSize: '100px' }}
                                    />
                                </div>
                                <CardContent
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                        width: '88%',
                                        flexDirection: 'column',
                                        textAlign: 'start',
                                        height: '35%'
                                    }}>
                                    <Typography
                                        className="documentText"
                                        variant="body2"
                                        style={{
                                            textAlign: 'start',
                                            fontWeight: '600',
                                            alignItems: 'center'
                                        }}>
                                        {val.name}
                                    </Typography>
                                    {/* <Typography
                    className="documentText"
                    variant="caption"
                    color="textSecondary"
                    style={{
                      textAlign: 'start',
                      fontWeight: '600',
                    }}>
                    {`Dated: ${date}`}
                  </Typography> */}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                </CSSTransition>
            );
        });
    };

    render() {
        let noc = Math.floor(this.props.windowWidth / 200);
        noc = noc - 1;
        let x = '';
        for (let i = 0; i < noc; i++) {
            x = x + 'auto';
            if (i + 1 < noc) x = x + ' ';
        }

        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={NOTICE} />
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        <div
                            style={{
                                width: '65%',
                                display: 'flex',
                                textAlign: 'start',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}>
                                <KeyboardBackspaceIcon
                                    color="primary"
                                    style={{
                                        marginRight: '15px',
                                        fontSize: '50px'
                                    }}
                                />
                            </IconButton>
                            <Typography
                                color="primary"
                                variant="h4"
                                style={{
                                    fontWeight: '600',
                                    marginLeft: '8px'
                                }}>
                                Notice
                            </Typography>
                        </div>
                        {this.props.windowWidth > 640 ? (
                            <RadioGroup
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}
                                aria-label="gender"
                                name="gender1"
                                value={this.state.value}
                                onChange={this.handleChangeRadio}>
                                <FormControlLabel
                                    value="show top 20"
                                    control={<Radio color="primary" />}
                                    label="Show latest 20"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="show all"
                                    control={<Radio color="primary" />}
                                    label="Show all"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        ) : null}
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '5px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.props.windowWidth <= 640 ? (
                    <RadioGroup
                        style={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around'
                        }}
                        aria-label="gender"
                        name="gender1"
                        value={this.state.value}
                        onChange={this.handleChangeRadio}>
                        <FormControlLabel
                            value="show top 20"
                            control={<Radio color="primary" />}
                            label="Show latest 20"
                            labelPlacement="start"
                        />{' '}
                        <FormControlLabel
                            value="show all"
                            control={<Radio color="primary" />}
                            label="Show all"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                ) : null}
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',

                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'grid',
                            gridTemplateColumns: x,
                            justifyContent:
                                noc <= 1 ? 'space-around' : 'space-between',
                            padding: '8px',
                            rowGap: '30px'
                        }}>
                        {this.renderItems()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No data avaliable
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(NoticePage);
