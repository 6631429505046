import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    IconButton,
    Typography,
    Divider,
    Card,
    CardMedia
} from '@material-ui/core';
import { SemipolarLoading } from 'react-loadingg';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CSSTransition } from 'react-transition-group';
import noimage from '../../../assets/user.jpg';
import Analytics from '../../../analytics';
import { ELECTION_COMMITTEE } from '../../../analytics/analytics';
import HelmetConfig from '../../../components/HelmetConfig/index';
const rooturl = process.env.REACT_APP_ROOT_DOCUMENT_FOLDER;
const root = process.env.REACT_APP_ROOT_APIS;

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: 'Election Commission',
            data: [],
            isLoading: true,
            inItems: false
        };
    }

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        setTimeout(() => {
            this.fetchData();
        }, 300);
    };

    fetchData = () => {
        fetch(
            `${root}organization.php?category=${this.state.pageName}&type=Image`
        )
            .then((response) => {
                response
                    .json()
                    .then((res) => {
                        this.setState({ isLoading: false, data: res }, () => {
                            this.setState({ inItems: true });
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ isLoading: false });
                    });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    renderItems = () => {
        return this.state.data.map((val, ind) => {
            return (
                <CSSTransition
                    timeout={700}
                    in={this.state.inItems}
                    classNames={'documentTrans'}>
                    <Card
                        style={{
                            width: '400px',
                            height: '225px',
                            overflow: 'hidden'
                        }}>
                        <div
                            style={{
                                width: '95%',
                                display: 'flex',
                                alignItems: 'flex-start',

                                flexDirection: 'column',
                                overflow: 'hidden',
                                padding: '8px'
                            }}>
                            <Typography
                                color="primary"
                                variant="body1"
                                style={{
                                    fontWeight: '600',
                                    width: 'fit-content'
                                }}>
                                {val.designation}
                            </Typography>
                            <Divider
                                style={{
                                    width: '100%',
                                    height: '1px',

                                    backgroundColor: '#7b3f00'
                                }}></Divider>
                        </div>
                        <div
                            style={{
                                width: '95%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                overflow: 'hidden',
                                padding: '8px',
                                height: '160px'
                            }}>
                            <CardMedia
                                title={val.name}
                                style={{ width: '30%', height: '100%' }}
                                image={
                                    val.img === '' || val.img === null
                                        ? noimage
                                        : rooturl + val.img
                                }></CardMedia>
                            <div
                                style={{
                                    width: '70%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingLeft: '8px',
                                    alignItems: 'flex-start'
                                }}>
                                <Typography
                                    className="documentText"
                                    color="textPrimaary"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start',
                                        height: '40px'
                                    }}
                                    variant="body2">
                                    {val.name}
                                </Typography>
                                <Typography
                                    className="departmentText"
                                    color="textSecondary"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start',
                                        height: '60px',
                                        marginTop: '3px'
                                    }}
                                    variant="caption">
                                    {val.department === ''
                                        ? 'Designation : Not Avaliable'
                                        : val.department}
                                </Typography>
                                <Typography
                                    className="documentText"
                                    color="textSecondary"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start',
                                        height: '20px',
                                        marginTop: '3px'
                                    }}
                                    variant="caption">
                                    {val.phone === ''
                                        ? 'phone: Not Avaliable'
                                        : 'phone: ' + val.phone}
                                </Typography>
                                <Typography
                                    className="documentText"
                                    color="textSecondary"
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'start',
                                        height: '40px',
                                        marginTop: '3px'
                                    }}
                                    variant="caption">
                                    {'email: ' + val.email}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </CSSTransition>
            );
        });
    };

    render() {
        let noc = Math.floor(this.props.windowWidth / 320);
        noc = noc - 1;
        let x = '';
        for (let i = 0; i < noc; i++) {
            x = x + 'auto';
            if (i + 1 < noc) x = x + ' ';
        }
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={ELECTION_COMMITTEE} />
                <div
                    style={{
                        width: '100%',
                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            {this.state.pageName}
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '5px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                {this.state.isLoading ? (
                    <div
                        style={{
                            width: '100%',
                            height: '40vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <SemipolarLoading
                            color="#7b3f00"
                            size="large"
                            style={{
                                position: 'relative'
                            }}
                        />
                    </div>
                ) : this.state.data.length ? (
                    <div
                        style={{
                            width: '95%',
                            display: 'grid',
                            gridTemplateColumns: x,
                            justifyContent:
                                noc <= 2 ? 'space-around' : 'space-between',
                            padding: '8px',
                            rowGap: '30px'
                        }}>
                        {this.renderItems()}
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Typography variant="h5" color="textSecondary">
                            No data avaliable
                        </Typography>
                    </div>
                )}
                <div style={{ height: '40px' }} />
            </div>
        );
    }
}

export default withRouter(Index);
