import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Typography, Divider } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { CONTACT } from '../../analytics/analytics';
import Analytics from '../../analytics';
import HelmetConfig from '../../components/HelmetConfig/index';
class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: {
                //address having three address lines
                address: [
                    'GOENKA COLLEGE OF COMMERCE AND BUSINESS ADMINISTRATION',
                    '210, Bepin Behari Ganguly Street',
                    'Kolkata - 700 012'
                ],
                phone: '(033) 2219 2061',
                email: 'wbgcta1967@gmail.com'
            },
            addressIn: false
        };
    }

    componentDidMount = () => {
        Analytics.pageView(window.location.pathname);
        this.setState({ addressIn: true });
    };

    render() {
        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '92vh',
                    alignItems: 'center',
                    flex: '1'
                }}>
                <HelmetConfig data={CONTACT} />
                <div
                    style={{
                        width: '100%',

                        height:
                            this.props.windowWidth > 1150 ? '150px' : '125px'
                    }}></div>
                <div
                    style={{
                        width: '95%',
                        padding: '15px',
                        marginBottom: '10px',
                        alignSelf: 'center',
                        display: 'flex',
                        textAlign: 'start',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            width: '95%',
                            display: 'flex',
                            textAlign: 'start',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                this.props.history.goBack();
                            }}>
                            <KeyboardBackspaceIcon
                                color="primary"
                                style={{
                                    marginRight: '15px',
                                    fontSize: '50px'
                                }}
                            />
                        </IconButton>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ fontWeight: '600', marginLeft: '8px' }}>
                            Contacts
                        </Typography>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            height: '1px',
                            marginTop: '8px',
                            backgroundColor: '#7b3f00'
                        }}></Divider>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginBottom: '30px'
                    }}>
                    <CSSTransition
                        timeout={1000}
                        in={this.state.addressIn}
                        classNames={'crouselInAnimation'}>
                        <div
                            style={{
                                display: 'flex',
                                flex: '1',
                                minWidth: '300px',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                flexDirection: 'column',
                                padding: '20px',
                                paddingLeft: '35px',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '7px'
                                }}>
                                <LocationOnIcon />
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginLeft: '10px',
                                        fontWeight: '600'
                                    }}>
                                    Address
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '15px'
                                }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: 'start',
                                        marginLeft: '30px'
                                    }}>
                                    {this.state.contact.address[0]}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: 'start',
                                        marginLeft: '30px'
                                    }}>
                                    {this.state.contact.address[1]}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: 'start',
                                        marginLeft: '30px'
                                    }}>
                                    {this.state.contact.address[2]}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '7px'
                                }}>
                                <EmailIcon />
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginLeft: '10px',
                                        fontWeight: '600'
                                    }}>
                                    Email
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '15px'
                                }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: 'start',
                                        marginLeft: '30px'
                                    }}>
                                    {this.state.contact.email}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '12px'
                                }}>
                                <PhoneIcon />
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginLeft: '10px',
                                        fontWeight: '600'
                                    }}>
                                    Phone
                                </Typography>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '15px'
                                }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        textAlign: 'start',
                                        marginLeft: '30px'
                                    }}>
                                    {this.state.contact.phone}
                                </Typography>
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        timeout={1000}
                        in={this.state.addressIn}
                        classNames={'postInAnimation'}>
                        <div
                            style={{
                                display: 'flex',
                                flex: '2',
                                height: '450px',
                                minWidth: '300px',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <GoogleMap />
                        </div>
                    </CSSTransition>
                </div>
            </div>
        );
    }
}

export default withRouter(ContactPage);
